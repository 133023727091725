import React from "react";
import FunctionPageLayout from "../components/functionPageLayout";
import { graphql } from "gatsby";
import Lightbox from "../components/lightBox";
import SEO from "../components/seo";
import Container from "../components/container";
import Columns from "../components/columns";

export const query = graphql`
  query {
    poczta: file(relativePath: { eq: "skrzynka-pocztowa.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const Imap = ({ data }) => {
  return (
    <FunctionPageLayout title="Skrzynka pocztowa" headline="Protokół IMAP">
      <SEO
        title="Skrzynka pocztowa"
        description="Skrzynka pocztowa w systemie dla agencji reklamowej i marketingowej ✅ Protokół IMAP • Zapytaj o bezpłatne demo!"
        keywords={["skrzynka pocztowa"]}
      />
      <Container className="container container--text">
        <p>
          <strong>Wiadomości e-mail</strong> pozostają niezwykle ważnym kanałem
          komunikacji w każdej agencji reklamowej. Pracownicy działów sprzedaży
          i realizacji wysyłają wiele maili z ofertami, ustaleniami, projektami
          i dziesiątkami innych rzeczy. Empireo pomaga nad tym wszystkim
          zapanować dzięki funkcji integracji z pocztą poprzez protokół IMAP.
          Nasze rozwiązanie pomaga uniknąć chaosu, pomyłek i czasochłonnego
          poszukiwania konkretnych maili.
        </p>
      </Container>
      <Container className="container container--text">
        <Columns>
          <div className="text-answers">
            <div className="text-block">
              <h3>Kluczowe możliwości: </h3>
              <p>
                <ul>
                  <li>
                    <strong>Współpraca z klientem pocztowym</strong>, czyli
                    możliwość odbioru i wysyłki wiadomości z poziomu systemu
                    Empireo.
                  </li>
                  <li>
                    <strong>
                      Wiązanie z kontrahentem wysłanych i odebranych wiadomości
                    </strong>
                    , dzięki czemu w jego kartotece tworzy się historia
                    korespondencji mailowej.
                  </li>
                  <li>
                    <strong>
                      Tworzenie zadań lub zdarzeń z poziomu wiadomości mailowej.
                    </strong>
                  </li>
                  <li>
                    <strong>Tworzenie szablonów wiadomości</strong>, bez
                    ograniczeń w ich liczbie. Koniec z mozolnym tworzeniem
                    wiadomości od nowa lub przeklejaniem jej z pliku!
                  </li>
                  <li>
                    <strong>Dostęp do maili innych pracowników</strong> -
                    oczywiście w przypadku dysponowania odpowiednimi
                    uprawnieniami.
                  </li>
                </ul>
              </p>
            </div>
            <div className="text-block">
              <Lightbox
                style={{
                  maxWidth: 800,
                  margin: "0 auto",
                }}
                images={[data.poczta]}
                alts={[
                  "Dostęp do wiadomości e-mail z poziomu systemu do zarządzania agencją reklamową i marketingową",
                ]}
              />
            </div>
          </div>
        </Columns>
      </Container>
      <Container>
        <p>
          Integracja Empireo z pocztą przynosi wiele korzyści, pośród których
          najważniejszymi są{" "}
          <strong>oszczędność czasu i porządek w komunikacji</strong> z
          kontrahentami oraz tej wewnątrzfirmowej. Ułatwia też zastępowanie
          nieobecnych pracowników poprzez dostęp do ich skrzynek pocztowych.
        </p>
      </Container>
    </FunctionPageLayout>
  );
};

export default Imap;
